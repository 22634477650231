import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'
import {getToken} from "@/utils/utils"

Vue.use(VueRouter)

export const constantRoutes = [
    {
        path: '/login',
        component: () => import('@/views/Login'),
        hidden: true,

    },
    {
        path: '/404',
        component: () => import('@/views/error/404'),
        hidden: true
    },
    {
        path: '/401',
        component: () => import('@/views/error/401'),
        hidden: true
    },
    {
        path: '',
        component: Layout,
        redirect: 'index',
        children: [
            {
                path: 'index',
                name: 'Index',
                component: () => import('@/views/Index'),
                meta: {title: '首页', icon: 'dashboard', affix: true, navPath: ["统计面板"]}
            },
            {
                path: 'shops/info',
                name: 'shops',
                component: () => import('@/views/shops/ShopsForm'),
                meta: {title: '商铺信息', icon: 'dashboard', affix: true, navPath: ["店铺管理", "商铺信息"]}
            },
            {
                path: 'products/index',
                name: 'products',
                component: () => import('@/views/products/Index'),
                meta: {title: '商品管理', icon: 'dashboard', affix: true, navPath: ["商品管理", "商品列表"]}
            },
            {
                path: 'usersPayment/index',
                name: 'usersPayment',
                component: () => import('@/views/usersPayment/Index'),
                meta: {title: '收款方式', icon: 'dashboard', affix: true, navPath: ["财务管理", "收款方式"]}
            },
            {
                path: 'transactionsWithdraw/index',
                name: 'transactionsWithdraw',
                component: () => import('@/views/transactionsWithdraw/Index'),
                meta: {title: '收款方式', icon: 'dashboard', affix: true, navPath: ["财务管理", "提现管理"]}
            },
            {
                path: 'invoices/index',
                name: 'invoices',
                component: () => import('@/views/invoices/Index'),
                meta: {title: '开票列表', icon: 'dashboard', affix: true, navPath: ["订单管理", "开票列表"]}
            },
            {
                path: 'orders/index',
                name: 'orders',
                component: () => import('@/views/orders/Index'),
                meta: {title: '开票列表', icon: 'dashboard', affix: true, navPath: ["订单管理", "订单列表"]}
            },
            {
                path: 'delivery/index',
                name: 'delivery',
                component: () => import('@/views/delivery/Index'),
                meta: {title: '配送员工', icon: 'dashboard', affix: true, navPath: ["配送员工", "配送列表"]}
            },
            {
                path: 'statistics/dayCount',
                name: 'dayCount',
                component: () => import('@/views/statistics/DayCount'),
                meta: {title: '店铺统计', icon: 'dashboard', affix: true, navPath: ["店铺统计", "每日统计"]}
            },
            {
                path: 'statistics/monthCount',
                name: 'monthCount',
                component: () => import('@/views/statistics/MonthCount'),
                meta: {title: '店铺统计', icon: 'dashboard', affix: true, navPath: ["店铺统计", "每月统计"]}
            },
            {
                path: 'statistics/productCount',
                name: 'productCount',
                component: () => import('@/views/statistics/ProductCount'),
                meta: {title: '店铺统计', icon: 'dashboard', affix: true, navPath: ["店铺统计", "商品统计"]}
            },
            {
                path: 'users/index',
                name: 'users',
                component: () => import('@/views/users/Index'),
                meta: {title: '配送员工', icon: 'dashboard', affix: true, navPath: ["配送员工", "员工列表"]}
            },
            {
                path: 'replys/index',
                name: 'replys',
                component: () => import('@/views/replys/Index'),
                meta: {title: '商品管理', icon: 'dashboard', affix: true, navPath: ["商品管理", "订单评论"]}
            },
        ]
    },
]

export const dynamicRoutes = []

const router = new VueRouter({
    routes: constantRoutes,
    scrollBehavior: () => ({y: 0}),
    mode: "hash"
})

router.beforeEach((to, from, next) => {
    const token = getToken();
    if (to.path !== '/login' && !token) {
        next('/login');
    } else {
        next();
    }
});

export default router
