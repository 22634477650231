<template>
  <editor :init="options" />
</template>

<script>
import tinymce from 'tinymce/tinymce'
import Editor from '@tinymce/tinymce-vue'

const plugins = 'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons'
const toolbar = 'fontsize code undo redo  bold italic underline strikethrough  image media table link'
const config = require('@/utils/config')

export default {
  components: {
    Editor
  },
  data() {
    return {
      options: {
        language_url: '/tinymce/langs/zh_CN.js',
        language: 'zh_CN',
        skin_url: '/tinymce/skins/ui/oxide',
        plugins: plugins,
        toolbar: toolbar,
        height: 500,
        branding: false,
        menubar: false,
        images_upload_url: config.uploadTinyUrl,
      }
    }
  },
  mounted() {
    tinymce.init({})
  },
  created() {
    window.tinymce.baseURL = '/tinymce'
  },
  methods: {}
}
</script>
<style>
.tox-silver-sink {
  z-index: 13000 !important;
}
</style>
