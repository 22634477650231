<template>
  <el-upload
      :action="uploadUrl"
      :headers="uploadHeaders"
      :multiple="multiple"
      :limit="limit"
      :list-type="listType"
      :on-exceed="handleExceed"
      :before-upload="beforeUpload"
      :on-success="handleSuccess"
      :on-error="handleError"
      :file-list="fileList">
    <slot name="trigger">
      <el-button size="small" type="primary">点击上传</el-button>
    </slot>
  </el-upload>
</template>

<script>
import {getToken} from '@/utils/utils'

let config = require('@/utils/config')
export default {
  name: 'MyUpload',
  props: {
    uploadUrl: {
      type: String,
      required: false,
      default: config.uploadUrl
    },
    uploadHeaders: {
      type: Object,
      default: () => ({
        Authorization: 'Bearer ' + getToken(),
      }),
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: 1,
      required: false,
    },
    listType: {
      type: String,
      default: "picture",
    },
    fieldName: {
      type: String,
      required: false,
      default: "",
    },
    showFileList: {
      type: Boolean,
      default: false,
    },
    fileSizeLimit: {
      type: Number,
      default: 10, // MB
    },
    fileList: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleExceed(files, fileList) {
      this.$message.warning(`只能上传${this.limit}个文件`);
    },
    beforeUpload(file) {
      const isSizeValid = file.size / 1024 / 1024 < this.fileSizeLimit;
      if (!isSizeValid) {
        this.$message.error(`文件大小不能超过${this.fileSizeLimit}MB`);
      }
      return isSizeValid;
    },
    handleSuccess(response, file, fileList) {
      this.$emit('success', response, file, fileList, this.fieldName);
    },
    handleError(error, file, fileList) {
      this.$message.error(error);
      this.$emit('error', error, file, fileList, this.fieldName);
    },
  },
};
</script>
