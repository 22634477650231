export const getGuidVCode = function () {
    let localGuid = localStorage.getItem("guid");
    if (localGuid != "" && localGuid != null) {
        return localGuid;
    }
    let guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
    localStorage.setItem("guid", guid);
    return guid;
}

export const getFileNameByUrl = function (url) {
    if (url == '' || url == undefined || url == null) {
        return;
    }
    let num = url.lastIndexOf('/') + 1
    let name = url.substring(num)
    return name;
}

export const setToken = function (token) {
    localStorage.setItem("token", token);
}
export const getToken = function () {
    return localStorage.getItem("token");
}

export const setLoginName = function (loginName) {
    localStorage.setItem("loginName", loginName);
}

export const getLoginName = function () {
    return localStorage.getItem("loginName");
}

export const removeUser = function () {
    localStorage.removeItem("loginName");
}




