<template>
  <el-container class="m-container">
    <el-aside class="m-aside" :class="isCollapse?'collapse':''">
      <el-scrollbar>
        <div class="side-menu-wrapper">
          <div class="logo-small" v-if="isCollapse">
            <img src="../assets/small-logo.png"/>
          </div>
          <div class="logo" v-else>
            微水站管理
          </div>
          <el-menu
              background-color="#304156"
              active-text-color="#409EFF"
              text-color="#fff"
              mode="vertical"
              :collapse="isCollapse"
              :unique-opened="true"
              :collapse-transition="false"
              :default-active="$route.fullPath"
              router>
            <el-submenu index="/shops">
              <template #title><i class="el-icon-s-shop"></i>
                <span>店铺管理</span>
              </template>
              <el-menu-item index="/shops/info">
                <i class="el-icon-tickets"></i>店铺信息
              </el-menu-item>
            </el-submenu>
            <el-submenu index="/products">
              <template #title><i class="el-icon-sell"></i>
                <span>产品管理</span>
              </template>
              <el-menu-item index="/products/index">
                <i class="el-icon-tickets"></i>产品列表
              </el-menu-item>
            </el-submenu>
            <el-submenu index="/order">
              <template #title><i class="el-icon-s-order"></i>
                <span>订单管理</span>
              </template>
              <el-menu-item index="/orders/index">
                <i class="el-icon-tickets"></i>订单列表
              </el-menu-item>
              <el-menu-item index="/invoices/index">
                <i class="el-icon-tickets"></i>开票列表
              </el-menu-item>
              <el-menu-item index="/replys/index">
                <i class="el-icon-tickets"></i>订单评论
              </el-menu-item>
            </el-submenu>
            <el-submenu index="/users">
              <template #title><i class="el-icon-user-solid"></i>
                <span>配送员工</span>
              </template>
              <el-menu-item index="/users/index">
                <i class="el-icon-tickets"></i>员工列表
              </el-menu-item>
              <el-menu-item index="/delivery/index">
                <i class="el-icon-tickets"></i>配送列表
              </el-menu-item>
            </el-submenu>
            <el-submenu index="/cw">
              <template #title><i class="el-icon-money"></i>
                <span>财务管理</span>
              </template>
              <el-menu-item index="/transactionsWithdraw/index">
                <i class="el-icon-tickets"></i>申请提现
              </el-menu-item>
              <el-menu-item index="/usersPayment/index">
                <i class="el-icon-tickets"></i>收款银行
              </el-menu-item>
            </el-submenu>
            <el-submenu index="/statistics">
              <template #title><i class="el-icon-s-data"></i>
                <span>店铺统计</span>
              </template>
              <el-menu-item index="/statistics/dayCount">
                <i class="el-icon-tickets"></i>每日订单
              </el-menu-item>
              <el-menu-item index="/statistics/MonthCount">
                <i class="el-icon-tickets"></i>每月订单
              </el-menu-item>
              <el-menu-item index="/statistics/productCount">
                <i class="el-icon-tickets"></i>商品统计
              </el-menu-item>
            </el-submenu>
          </el-menu>
        </div>
      </el-scrollbar>
    </el-aside>
    <el-container class="r-container">
      <el-header class="header-bar-wrap">
        <div class="header-bar">
          <div class="side-trigger-btn" @click="onCollapse()">
            <i :class="isCollapse?'el-icon-s-fold':'el-icon-s-unfold'"></i>
          </div>
          <div class="custom-bread-crumb">
            <el-breadcrumb separator="/">
              <el-breadcrumb-item>
                <a href="/">首页</a>
              </el-breadcrumb-item>
              <el-breadcrumb-item v-for="item in $route.meta['navPath']" :key="item">
                {{ item }}
              </el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="custom-content-con">
            <el-dropdown>
              <span class="el-dropdown-link">
                {{ loginAccount }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click.native="onUpdatePwd">修改密码</el-dropdown-item>
                  <el-dropdown-item @click.native="onLogout">退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </div>
      </el-header>
      <el-main class="main-wrap">
        <el-scrollbar class="main-scroll">
          <router-view></router-view>
        </el-scrollbar>
        <el-backtop target=".main-scroll .el-scrollbar__wrap"></el-backtop>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
import {getLoginName} from '@/utils/utils'

export default {
  name: 'Layout',
  data() {
    return {
      isCollapse: false,
      loginAccount: ""
    }
  },
  created() {
    this.loginAccount = getLoginName();
  },
  methods: {
    onLogout() {
      this.$router.push({path: '/login'});
    },
    onUpdatePwd() {
      console.log(111);
    },
    onCollapse() {
      this.isCollapse = !this.isCollapse;
    },
  },
  components: {}
}
</script>
<style lang="scss" scoped>
$bgColor: #f0f2f5;
.logo {
  color: #ffffff;
  font-size: 18px;
}

.logo-small {
  margin-top: 20px;
  margin-left: 20px;
}

.m-container, .r-container {
  background-color: #ffffff;
  height: 100vh
}

.m-aside.collapse {
  width: 64px !important;
}

.m-aside {
  background-color: #304156;
  width: 210px !important;

  .side-menu-wrapper {
    .el-menu {
      border-right: 0;
      width: 100%;

      .el-submenu__title i, .el-menu-item i {
        color: #fff !important;
        opacity: .9;
      }

      .el-menu-item.is-active i {
        color: #409EFF !important;
      }
    }

    .logo {
      margin: 20px auto 20px 25px;
    }
  }
}

.header-bar-wrap {
  padding-left: 0px !important;
  padding-right: 0px !important;
  flex-shrink: 0;
  flex-direction: column;
  background-color: $bgColor;
  display: flex;
  height: auto !important;
  position: relative;

  .header-bar {
    height: 50px;
    line-height: 50px;
    width: 100%;
    align-items: center;
    display: flex;
    background-color: #fff;

    .side-trigger-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
    }

    .custom-bread-crumb {
      margin-left: 15px !important;
    }

    .custom-content-con {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: auto;
      cursor: default;
      padding-right: 20px;
    }
  }

  .tags-nav {
    width: calc(100% - 50px);
    overflow-x: hidden;

    .tag-wrap {
      display: flex;
      flex-wrap: nowrap;
      padding: 8px 10px;

      .el-tag {
        min-width: 60px;
        height: 28px;
        font-size: 12px;
        line-height: 28px;
        padding: 0 12px;
        margin-right: 6px;
        border-radius: 2px;
        border: 0;
        text-align: center;
      }
    }

    .tag-menu {
      position: absolute;
      right: 20px;
      top: 65px;
      font-size: 18px;
    }
  }
}

.main-wrap {
  background-color: $bgColor;
  padding: 0 10px 10px 10px;
  flex: 1;
  overflow: hidden;
  background-color: #f2f3f4;
}
</style>
