import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Element from 'element-ui'
import './assets/styles/element-variables.scss'
import MyUpload from './components/MyUpload'
import TinymceEditor from './components/TinymceEditor'

Vue.component("MyUpload", MyUpload)
Vue.component("TinymceEditor", TinymceEditor)

Vue.use(Element, {
    size: 'medium'
})
Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
